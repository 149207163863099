import React from 'react';

import Heading from '@/atoms/Heading';
import Grid from '@/atoms/Grid';
import Head from '@/components/Head';

export default () => {
  return (
    <>
      <Head title="(404) Page not found" meta={{ robotsIndex: false }} />

      <Grid.Container>
        <Heading h={1}>404</Heading>
      </Grid.Container>
    </>
  );
};
