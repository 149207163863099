import styled from 'styled-components';

import Box from '@/atoms/Box';

import { visuallyHidden, scale } from '@/styles/utils';
import { h1, h2, h3 } from '@/styles/typography';

export default styled(Box).attrs(props => ({
  as: props.as || `h${props.h}`,
}))`
  ${props => props.h === 1 && h1}
  ${props => props.h === 2 && h2}
  ${props => props.h === 3 && h3}

  ${props => props.hide && visuallyHidden}

  hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  white-space: pre-line;
  max-width: ${scale(50)};
`;
